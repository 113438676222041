<template>
  <a-modal width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('售后订单.是否同意')" prop="isPass" >
        <a-radio-group v-model="form.isPass" button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="!form.isPass" :label="$t('售后订单.拒绝原因')" prop="reason" >
        <a-input v-model="form.reason" :placeholder="$t('通用.输入.请输入')+$t('售后订单.拒绝原因')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.确定')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import {getRefund, auditRefund, updateSell, auditRefundBatch} from '@/api/order/refund'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";


export default {
  name: 'DeliveryForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      record: {
        id:null,
      } ,
      // 表单参数
      form: {
        id: null,
        idList:null,
        isPass:null,
        reason:null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isPass: [
          { required: true, message: this.$t('售后订单.是否同意')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        reason: [
          { required: true, message: this.$t('售后订单.拒绝原因')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.record={
        id:null,
      }
      this.form = {
        id: null,
        idList: null,
        isPass:true,
        reason:null,
      }

    },
    /** 修改按钮操作 */
    handleAudit(id,ids) {
      this.reset()
      this.formType = 2
      this.form.id= id
      this.form.idList=ids
      this.open = true
      this.formTitle = this.$t('售后订单.审核')
    },
    /** 提交按钮 */
    submitForm: function () {
      if (this.form.id) {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true
            let message= this.$t('售后订单.审核成功')
            auditRefund(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            return false
          }
        });
      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true
            auditRefundBatch(this.form).then(response => {
              this.$message.success(
                response.data,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            return false
          }
        });
      }


    }
  }
}
</script>
<style  lang="less" scoped>
.info-table {
  tr {
    height: 50px;
  }
  td {
    display: table-cell;
    vertical-align: top;
    font-size: 16px;
  }
  .first {
    width: 120px;
  }
  .data {
    width: 280px;
  }
}
</style>
